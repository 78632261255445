let tkCommon = {};

/*  
 *   场景一： 【重组航班列表数据】
 *   处理舱位价格重排
 *   flightList : 航班列表
 *   priceMap ：  航班列表价格map
 */
tkCommon.chuLiFlightCabinPriceArr = function(flightList, priceMap,planeMap,flightMap,tkQuery) {
	
	// 是否开启 隐藏头等、公务、高端经济舱参数，是否只显示最低价舱位
	let isShowGongWU = true,
		isShowTouDeng = true,
		isShowEsCabin = true,
		isOnlyMinPrice = false,
		isOnlyCabinTypeMinPrice = false; // 是否显示当前舱位类型下的最低价（官网、协议、普通）特价、政采， 不受全免影响
		
	if(tkQuery && tkQuery.tripType == 1  ){
		// 如果不是预订全免的
		if(tkQuery.appBook!=1){
			// 是否开启隐藏头等、公务舱参数控制  默认不开启隐藏  '0'  10997
			// 是否开启隐藏高端经济舱参数控制   默认不开启隐藏  '0'  20028
			// 是否开启最低价控制 10994 默认 0
			// var isOnlyMinPriceGk  = this.getVipParamterByParNo('10994','0'); 
			
			// 公务舱
			if(tkQuery.firstAndBussinessGK ==1 &&(tkQuery.isDomesticBusinessClass == 0 || !tkQuery.isDomesticBusinessClass) ){
				isShowGongWU = false;
			}
			// 头等舱
			if(tkQuery.firstAndBussinessGK ==1 &&(tkQuery.isDomesticFirstClass == 0 || !tkQuery.isDomesticFirstClass) ){
				isShowTouDeng = false;
			}
			// 高端经济舱
			if(tkQuery.firstAndBussinessGK ==1 &&(tkQuery.isDomesticHighClass == 0 || !tkQuery.isDomesticHighClass) ){
				isShowEsCabin = false;
			}
				
			// console.log(isShowEsCabin,isShowGongWU,isShowTouDeng,'ES','C','F')
		}
		
		//  当开启航班查询，同一舱位类型，显示最低价格产品（普通、官网、协议）,仅因公模式下，限制
		if(tkQuery.isOnlySameCabinMinPriceGk == 1){
			isOnlyCabinTypeMinPrice = true;
		}
		
		
		
			
	}
	
	var newFlightList = [];
	newFlightList = flightList.map((flightItem, flightIndex) => {
		let flightObj = flightItem;

		let cabinTypeList = flightItem.cabinTypeList || [];
		let cabinMap = flightItem.cabinMap || {};
		let minPriceItem = {}; // 当前最低价价格项
		flightObj.cabinTypeList = cabinTypeList.map((cabinTypeItem, cabinIndex) => {
			
			// 0 特价  1 普通 2官网 3协议 4政采 5增值服务      
			// 1.单个航班，同一舱位等级，舱位数据重组
			cabinTypeItem.priceArr = tkCommon.cabinGroup(cabinTypeItem, cabinMap, priceMap);
			// 2.单个航班，同一舱位等级，选出最低价官网 2  isMinGuanWang  true：显示最低价官网  
			// cabinTypeItem.priceArr = tkCommon.chuLiMinPriceByOneProductType(cabinTypeItem.priceArr,['2'],'isMinGuanWang');
		
			//  仅因公： 2A:单个航班，同一舱位等级，选出最低价协议 和 普通价  【这种情况下，福老板说，特价、政采、增值服务都视为普通价】
			// 【因电建需求】【协议价】也纳入普通价之列
			//  同一等级最低价控制，转为后台控制
			if(isOnlyCabinTypeMinPrice){
				// 同一舱位等级，最低价协议  3,'isMinXieYi'
				// cabinTypeItem.priceArr = tkCommon.chuLiMinPriceByOneProductType(cabinTypeItem.priceArr,['3'],'isMinXieYi');
				// 同一舱位等级 除（官网、协议）其他产品的最低价    !=2    !=3   isMinCommon   ['0','1','4','5']
				// cabinTypeItem.priceArr = tkCommon.chuLiMinPriceByOneProductType(cabinTypeItem.priceArr, ['0','1','3','4','5'],'isMinCommon');
			}
			// 3.当前舱位等级是否可以显示【因公模式下，是否满足企业员工预订要求】 isShow true：显示该舱位等级
			cabinTypeItem.isShow = true;
			cabinTypeItem =	tkCommon.isshowGongWuAndTouDeng(cabinTypeItem,{isShowEsCabin,isShowGongWU,isShowTouDeng})
			// 5.选出最低价的价格priceId项
			cabinTypeItem.priceArr.map((priceItem)=>{
				if(priceItem['priceId'] == flightObj['minPriceId']){
					minPriceItem = priceItem;
				}
			})
			
			return cabinTypeItem
		})
		
		// 处理共享航班问题
		if(flightObj['sharFlightNo']){
			var shareFlightItem = flightMap[flightObj['sharFlightNo']] || {};
		
			flightObj['shareAirline'] = shareFlightItem['airline'];
			flightObj['shareAirlineImg'] = shareFlightItem['airlineImg'];
			flightObj['shareAirlineName'] = shareFlightItem['airlineName'];
		}
		
		// 处理机型问题   
		
		if(flightObj['plane'] && planeMap[flightObj['plane']]){
			let planeObj = planeMap[flightObj.plane];
			for(var planeItemKey in planeObj){
				// 处理机型名称中含有的中文括号问题
				if(planeItemKey == 'planeName'){
					planeObj[planeItemKey] = planeObj[planeItemKey].replace(/（/,'(').replace(/）/,')');
				}
				flightObj[planeItemKey] = planeObj[planeItemKey];
				
				
			}
		}
		// 修改机型判断方案
		let planeName = flightObj['planeName'] || '';
		if(planeName){
			if(planeName.indexOf('小')>-1){
				flightObj['planeModelSize'] = 1;
				flightObj['planeModelSizeName'] = '小型机';
			}else if(planeName.indexOf('中')>-1){
				flightObj['planeModelSize'] = 2;
				flightObj['planeModelSizeName'] = '中型机';
			}else if(planeName.indexOf('大')>-1){
				flightObj['planeModelSize'] = 3;
				flightObj['planeModelSizeName'] = '大型机';
			}else{
				flightObj['planeModelSize'] = 999;
				flightObj['planeModelSizeName'] = '其他机型';
			}
		}else{
			flightObj['planeModelSize'] = 999;
			flightObj['planeModelSizeName'] = '其他机型';
		}
		
		
		// 计划机型  小型机:1  -100座   中型机:2  100 - 200 座  大型机:3  200+座   默认 999：其他机型
		// if(flightObj['maxSeat']){
		// 	if(flightObj['maxSeat'] < 100){
		// 		flightObj['planeModelSize'] = 1;
		// 		flightObj['planeModelSizeName'] = '小型机';
		// 	}else if(100<= flightObj['maxSeat'] && flightObj['maxSeat'] <=200 ){
		// 		flightObj['planeModelSize'] = 2;
		// 		flightObj['planeModelSizeName'] = '中型机';
		// 	}else if(flightObj['maxSeat'] >200){
		// 		flightObj['planeModelSize'] = 3;
		// 		flightObj['planeModelSizeName'] = '大型机';
		// 	}else{
		// 		flightObj['planeModelSize'] = 999;
		// 		flightObj['planeModelSizeName'] = '其他机型';
		// 	}
		// }else{
		// 	flightObj['planeModelSize'] = 999;
		// 	flightObj['planeModelSizeName'] = '其他机型';
		// }
		
		/* 起飞时间
			凌晨：1   00:00-06:00 、
			上午：2   06:00-12:00 、
			下午：3   12:00-18:00 、
			晚上：4  18:00-24:00
			 0：未知时间
		*
		*/  
		if(flightObj['departTime']){
			let depTimeObj = tkCommon.timeHandle(flightObj['departTime'])
			
			flightObj['departTimeYjsl'] = depTimeObj['code'];
			flightObj['departTimeYjslName'] = depTimeObj['name'];
		}
		// // 降落时间
		if(flightObj['arriveTime']){
			let arrTimeObj = tkCommon.timeHandle(flightObj['arriveTime'])
			flightObj['arriveTimeYjsl'] = arrTimeObj['code'];
			flightObj['arriveTimeYjslName'] = arrTimeObj['name'];
		}
		// 正常单和改签单是否显示后台处理逻辑
		if(flightObj['isshow'] || !flightObj.hasOwnProperty('isshow')){
			flightObj['isNoShow'] = false;
		}else{
			flightObj['isNoShow'] = true;
		}
		
		// 最低价处理  【minFilterPrice】  用于不同舱位筛选时，最低价处理
		
		flightObj['minFilterPrice'] =  minPriceItem['salePrice'];
		flightObj['minFilterPriceCabin'] = minPriceItem['cabin'];
		flightObj['minFilterPriceCabinType'] = minPriceItem['cabinType'];
		flightObj['minFilterPriceCabinTypeName'] = minPriceItem['cabinTypeName'];
		flightObj['minFilterPriceId'] = minPriceItem['priceId'];
		
		flightObj['minPriceItem'] = minPriceItem;
		
		// 处理直飞、经停问题  Math.random()*10;  0 直飞 1 经停
		let stopNum = flightObj['stopNum']  || 0 ;
		if(stopNum>0){
			flightObj['stopNum'] = 1;
		}else{
			flightObj['stopNum'] = 0;
		}
		

		return flightObj
	})
	// console.log(newFlightList)
	return newFlightList
};
/*
 *  舱位数据重组
 *  cabinTypeItem：舱位类型单项
 *  cabinMap：舱位map
 *  priceMap: 航班列表价格map
 */
tkCommon.cabinGroup = function(cabinTypeItem, cabinMap, priceMap) {
	var cabintypes = cabinTypeItem.cabinList || [];
	var priceArr = [];

	cabintypes.forEach((item, index) => {
		var cabin = cabinMap[item] || {};
		var priceList = cabin.priceList || [];
		var price = tkCommon.priceGroup(priceList, cabin, priceMap)
		priceArr.push(price);
	})
	// 数组降低维度
	priceArr = Array.prototype.concat.apply([], priceArr);

	return priceArr
};
/*	  
 *  价格重组
 *  priceList：价格list
 *  cabin: 当前舱位代码
 *  priceMap ：  航班列表价格map
 */
tkCommon.priceGroup = function(priceList, cabin, priceMap) {
	var priceList = priceList || [];
	var prices = [];
	for (var i in priceList) {
		var result = {};
		var price = priceMap[priceList[i]] || {};
		for (var j in cabin) {
			result[j] = cabin[j];
		}
		for (var j in price) {
			result[j] = price[j];
		}

		prices.push(result);
	}
	return prices;
};
// 处理同一舱位，单个类型产品的最低价
// 处理官网价  1.选出同一产品类型 2.选出该产品类型的最低价   
// 例：productType   ：  0 特价  1 普通 2官网 3协议 4政采 5增值服务    
// minPriceSignName ：  官网：isMinGuanWang   协议：isMinXieYi   其他 isMinCommon

tkCommon.chuLiMinPriceByOneProductType = function(cabinList,productTypeArr,minPriceSignName) {
	
	var sameProductList = cabinList.filter((item)=> {
			return productTypeArr.indexOf(item.productType)>-1
	});
	sameProductList = tkCommon.maoPaoSort(sameProductList, 'salePrice');
	let minSameProductItem = sameProductList[0] || {};
	let minPriceId = minSameProductItem['priceId'] || '';
	
	cabinList = cabinList.map((item)=> {
		if (item.priceId == minPriceId) {
			item[minPriceSignName] = true;
		}
		// 如果存在相同价格都展示
		if(item.salePrice == minSameProductItem.salePrice){
			item[minPriceSignName] = true;
		}
		return item
	})

	return cabinList
};
// // 处理官网价  1.选出官网 2.选出最低价官网   isMinGuanWang
// tkCommon.chuLiGWPrice = function(cabinList) {
// 	var guangWangList = cabinList.filter(function(item) {
// 		return (item.productType == 2)
// 	});
// 	guangWangList = tkCommon.maoPaoSort(guangWangList, 'salePrice');

// 	var minPriceId = guangWangList[0] ? guangWangList[0]['priceId'] : '';
// 	cabinList = cabinList.map(function(item) {
// 		if (item.priceId == minPriceId) {
// 			item['isMinGuanWang'] = true;
// 		}
// 		return item
// 	})

// 	return cabinList
// };
//简单的冒泡排序
tkCommon.maoPaoSort = function(arr, typeName) {
	for (var i in arr) {
		for (var y in arr) {
			if (arr[i][typeName] < arr[y][typeName]) {
				var times = arr[i];
				arr[i] = arr[y];
				arr[y] = times;
			}
		}
	}
	return arr
};

//  简单的处理企业参数舱位筛选问题
tkCommon.isshowGongWuAndTouDeng = function(cabinType,{isShowEsCabin,isShowGongWU,isShowTouDeng}) {
	// console.log(isShowEsCabin,isShowGongWU,isShowTouDeng,'ES','C','F','+++++++++++')
	let cabinTypeItem = cabinType;
	// 为了防止错误
	if(cabinType['cabinTypeName'] && cabinType['cabinType']){
		
		if(cabinType['cabinType'] == 'C' || cabinType['cabinType'] == 'F' || cabinType['cabinType'] == 'ES' ||  cabinType['cabinTypeName'].indexOf('公务舱') > -1 || cabinType['cabinTypeName'].indexOf('头等舱') > -1 || cabinType['cabinTypeName'].indexOf('高端经济舱') > -1) {
			cabinType['isShow'] = false;
		}
		// 是否显示高端经济舱【明珠舱】
		if(isShowEsCabin && (cabinType['cabinType'] == 'ES' || cabinType['cabinTypeName'].indexOf('高端经济舱') > -1)) {
			cabinType['isShow'] = true;
		}
		//  是否显示公务舱
		if(isShowGongWU && (cabinType['cabinType'] == 'C' || cabinType['cabinTypeName'].indexOf('公务舱') > -1)) {
			cabinType['isShow'] = true;
		}
		//  是否显示头等舱
		if(isShowTouDeng && (cabinType['cabinType'] == 'F' || cabinType['cabinTypeName'].indexOf('头等舱') > -1)) {
			cabinType['isShow'] = true;
		}
	}else{
		console.error(`舱位类型为${cabinType['cabinTypeName']},舱位类型代码为${cabinType['cabinType']}`)
	}
    
      			
    return cabinTypeItem
};



// 时间处理
tkCommon.timeHandle = function(time){
	
	/* 起飞时间 
		凌晨：1   00:00-06:00 、
		上午：2   06:00-12:00 、
		下午：3   12:00-18:00 、
		晚上：4  18:00-24:00
		 0：未知时间
	*
	*/  
   var chooseTimeItem = {};
	var timeMap = {
		'00:00-06:00':{name:"凌晨 0~6点",val:'00:00~06:00',code:1},
		'06:00-12:00':{name:"上午 6~12点",val:'06:00~12:00',code:2},
		'12:00-18:00':{name:"下午 12~18点",val:'12:00~18:00',code:3},
		'18:00-24:00':{name:"晚上 18~24点",val:'18:00~24:00',code:4}
	}
	
	for(var timeKey in timeMap){
		var timeArr = timeKey.split('-') || [];
		if( timeArr[0]<=time  && time < timeArr[1]){
			chooseTimeItem = timeMap[timeKey]
		}
		
	}
	return chooseTimeItem
}

/*
 *  场景二： 【获取航班筛选条件数据】
 *  	// 舱位类型map
 *		cabinTypeMap:{},
 *		// 航司map
 *		airlineMap:{},
 *		// 起抵时段
 *		depTimeMap:{},
 *		arrTimeMap:{},
 *		// 起飞机场
 *		depAirportMap:{},
 *		// 降落机场
 *		arrAirportMap:{},
 *		// 计划机型  小型机  -100座   中型机  100 - 200 座  大型机200+座
 *		planeMap:{},
 *   
 *    满足基本筛选条件功能
 *    计算该舱位筛选条件下。最低价展示
 *
 * 
 *
 ****/
tkCommon.getTkFilterData=function(flightList){
	
	var obj = {
		cabinTypeMap:{},
		airlineMap:{},
		depTimeMap:{},
		arrTimeMap:{},
		depAirportMap:{},
		arrAirportMap:{},
		planeMap:{},
		stopNumMap:{}
	};
	
	for(var k=0;k<flightList.length;k++){
		let flightItem = flightList[k];
		

		/*  // 舱位类型 
		 * 
		 */ 
		let cabinTypeList = flightItem.cabinTypeList || [];
		for(var c=0;c<cabinTypeList.length;c++){
			let cabinTypeItem = cabinTypeList[c];
			// 当前航班中 当前舱位类型的最低价
			let cabinMinPrice = cabinTypeItem['priceArr'][0]['salePrice'];
			let cabinTypeKey = cabinTypeItem['cabinType'];
			if(cabinTypeItem['isShow']){
				if(!obj.cabinTypeMap[cabinTypeKey] ){
					obj.cabinTypeMap[cabinTypeKey] = {
						"name":cabinTypeItem['cabinTypeName'],
						"code":cabinTypeKey,
						"minPrice":cabinMinPrice,
						'filterCountTotal':1,
						'filterCount':1,  // 用于显示是否 置灰 
					}
				}else{
					// 增加一条匹配航班数量
					obj.cabinTypeMap[cabinTypeKey]['filterCountTotal'] =  obj.cabinTypeMap[cabinTypeKey]['filterCountTotal'] + 1;
					obj.cabinTypeMap[cabinTypeKey]['filterCount'] = obj.cabinTypeMap[cabinTypeKey]['filterCount'] + 1;
					// 如果当前舱位类型，存在最低价，则替换为最低价
					if(cabinMinPrice < obj.cabinTypeMap[cabinTypeKey]['minPrice'] ){
						obj.cabinTypeMap[cabinTypeKey]['minPrice']  = cabinMinPrice
					}
					
				}
			}
			
		
		}

		// 当前航班的最低价
		let minPrice = flightItem.cabinTypeList[0]?flightItem.cabinTypeList[0]['priceArr'][0]['salePrice']:0;
		
		// 直飞经停
		// 直飞 经停
		let stopNum = flightItem['stopNum'] || 0;
		let stopNumName = stopNum > 0?'经停':'直飞';
		if(!obj.stopNumMap[stopNum]){
			obj.stopNumMap[stopNum] = {
				"name":stopNumName,
				"code":stopNum,
				"minPrice":minPrice,
				'filterCountTotal':1,
				'filterCount':1,
			}
		}else{
			obj.stopNumMap[stopNum]['filterCountTotal'] = obj.stopNumMap[stopNum]['filterCountTotal'] + 1;
			obj.stopNumMap[stopNum]['filterCount'] = obj.stopNumMap[stopNum]['filterCount'] + 1;
			if(minPrice < obj.stopNumMap[stopNum]['minPrice']){
				obj.stopNumMap[stopNum]['minPrice'] = minPrice;
			}
		}
	
		
		// 航司map
		let airlineKey = flightItem['airline'];
		if(!obj.airlineMap[airlineKey]){
			obj.airlineMap[airlineKey] = {
				"name":flightItem['airlineName'],
				"code":airlineKey,
				"img":flightItem['airlineImg'],
				"minPrice":minPrice,
				'filterCountTotal':1,
				'filterCount':1,
			}
		}else{
			obj.airlineMap[airlineKey]['filterCountTotal'] = obj.airlineMap[airlineKey]['filterCountTotal'] + 1;
			obj.airlineMap[airlineKey]['filterCount'] = obj.airlineMap[airlineKey]['filterCount'] + 1;
			if(minPrice < obj.airlineMap[airlineKey]['minPrice']){
				obj.airlineMap[airlineKey]['minPrice'] = minPrice;
			}
		}
		
		// 起飞机场
		let departAirportKey = flightItem['departAirport'];
		if(!obj.depAirportMap[departAirportKey] ){
			obj.depAirportMap[departAirportKey] = {
				"name":flightItem['departAirportName'],
				"code":departAirportKey,
				"minPrice":minPrice,
				'filterCountTotal':1,
				'filterCount':1,
			}
		}else{
			obj.depAirportMap[departAirportKey]['filterCountTotal'] ++;
			obj.depAirportMap[departAirportKey]['filterCount'] ++;
			if(obj.depAirportMap[departAirportKey]['minPrice']){
				obj.depAirportMap[departAirportKey]['minPrice'] = minPrice;
			}
		}
		
		// 到达机场
		let arriveAirportKey = flightItem['arriveAirport'];
		if(!obj.arrAirportMap[arriveAirportKey]){
			obj.arrAirportMap[arriveAirportKey] = {
				'name':flightItem['arriveAirportName'],
				'code':arriveAirportKey,
				'minPrice':minPrice,
				'filterCountTotal':1,
				'filterCount':1
			}
		}else{
			obj.arrAirportMap[arriveAirportKey]['filterCountTotal'] ++;
			obj.arrAirportMap[arriveAirportKey]['filterCount'] ++;
			if(minPrice < obj.arrAirportMap[arriveAirportKey]['minPrice']){
				obj.arrAirportMap[arriveAirportKey]['minPrice'] = minPrice;
			}
		}
		
		// 计划机型  前端模拟字段 planeModelSize
		let planeModelSizeKey = flightItem['planeModelSize'];
		if(planeModelSizeKey){
			if( !obj.planeMap[planeModelSizeKey]){
				obj.planeMap[planeModelSizeKey] = {
					"name":flightItem.planeModelSizeName,
					"code":flightItem.planeModelSize,
					"minPrice":minPrice,
					'filterCountTotal':1,
					'filterCount':1
				}
			}else{
			    obj.planeMap[planeModelSizeKey]['filterCountTotal'] ++;
				obj.planeMap[planeModelSizeKey]['filterCount'] ++;
				if(   minPrice < obj.planeMap[planeModelSizeKey]['minPrice']){
					obj.planeMap[planeModelSizeKey]['minPrice'] = minPrice;
				}
			}
		}
		
	
		// 起飞时间  凌晨：1、上午：2、下午：3、晚上：4
		let depTimeKey = flightItem['departTimeYjsl'];
		if(depTimeKey){
			if( !obj.depTimeMap[depTimeKey]){
				obj.depTimeMap[depTimeKey] = {
					"name":flightItem['departTimeYjslName'],
					"code":depTimeKey,
					"minPrice":minPrice,
					'filterCountTotal':1,
					'filterCount':1,
				}
			}else{
				obj.depTimeMap[depTimeKey]['filterCountTotal'] ++;
				obj.depTimeMap[depTimeKey]['filterCount'] ++;
				if(   minPrice < obj.depTimeMap[depTimeKey]['minPrice']){
					obj.depTimeMap[depTimeKey]['minPrice'] = minPrice;
				}
			}
		}
		
		
		// 降落时间
		let arriveTimeKey = flightItem['arriveTimeYjsl'];
		if(arriveTimeKey){
			if(!obj.arrTimeMap[arriveTimeKey]){
				obj.arrTimeMap[arriveTimeKey] = {
					"name":flightItem['arriveTimeYjslName'],
					"code":arriveTimeKey,
					"minPrice":minPrice,
					'filterCountTotal':1,
					'filterCount':1,
				}
			}else{
				obj.arrTimeMap[arriveTimeKey]['filterCountTotal'] ++;
				obj.arrTimeMap[arriveTimeKey]['filterCount'] ++;
				if(  minPrice < obj.arrTimeMap[arriveTimeKey]['minPrice']){
					obj.arrTimeMap[arriveTimeKey]['minPrice'] = minPrice;
				}
			}
		}
		
		
		
	
				
	}
	
	return obj
}

/*
*   【场景3】	航班筛选功能处理
*
*    @param {flghtList}
*	 @param {tkFilterObj}
			tkFilterObj={
				cabinTypeMap:{},
				airlineMap:{},
				depTimeMap:{},
				arrTimeMap:{},
				depAirportMap:{},
				arrAirportMap:{},
				planeMap:{},
			}   
*	
*	 @return {Array}
*
*    其中  【isShow】属性所有航班是否显示，是后台标识  
*         【isNoShow】属性：前端使用isNoShow属性，前端控制标识   默认显示 false， false：显示  || true：隐藏
*
*/ 


tkCommon.getFilterNewFlightList = function(flghtList,tkFilterObj){
	var newFlightList = [];
	
	// 清空tkFilterObj 的  filterCount
	
	// for(var filterKey in tkFilterObj){
	// 	for(var filterItemKey in tkFilterObj[filterKey]){
	// 		tkFilterObj[filterKey][filterItemKey]['filterCount'] = 0;
	// 	}
	// }
	
	newFlightList = flghtList.map((flightItem,flightIndex)=>{
		let isNoShow = true;
		//1.看后台航班是否展示【isshow】
		if(!flightItem['isshow']){
			isNoShow = true; // 该航班不展示，用于处理临近航班
		}else{
			//2.是否满足筛选条件的筛选
			let newfilterObj = tkCommon.filterTkByFilterObj(flightItem,tkFilterObj)
			flightItem = newfilterObj.flightItem;
			
			if(newfilterObj.isNoShow){
				isNoShow = false;
			}
		}
	
		
		
		
		
		//最后赋值处理航班显示 
		flightItem['isNoShow'] = isNoShow;
		return flightItem
	})
	
	
	
	return newFlightList
}
//  筛选操作
// 增加一个属性当前筛选条件是否有满足的  【isNoShow】 用于控制筛选条件，是否可选
/***
*   筛选原则：
*   单独某一种筛选类型 Map，取 并集  || 
*   不同类型的筛选类型 Map，取 交集   &&
* 
    同种类型筛选
		
*
***/ 
 

tkCommon.filterTkByFilterObj = function(flightItem,tkFilterObj){
	
	// cabinTypeMap:{},
	// airlineMap:{},
	// depTimeMap:{},
	// arrTimeMap:{},
	// depAirportMap:{},
	// arrAirportMap:{},
	// planeMap:{},
	// 航班筛选条件满足度  默认：0
	var isNoShow = false; 
	var isManZu0 = false;
	var isManZu1 = false;
	var isManZu2 = false;
	var isManZu3 = false;
	var isManZu4 = false;
	var isManZu5 = false;
	var isManZu6 = false;
	var isManZu7 = false;
	
	let minCabinType = '';
	
	for(var filterKey in tkFilterObj){
		let filterItemMapIsNull = tkCommon.filterItemMapIsNull(tkFilterObj[filterKey]);
		
		
		
		
		// 舱位类型  
		/**
		 *  特别处理：
		 *    如果当前筛选项舱位类型中要求：经济舱不显示，只显示超值经济舱或者公务舱或者头等舱 
		 *    则处理当前航班的最低价问题，即找到最低价舱位等级,
		 * 	
		 *    修改航班最低价位可以显示的最低价舱位等级的最低价，【在最终条件中修改】
		 *    1. 如果筛选项：舱位类型为空，则 默认第一个舱位等级是 minCabinType
		 *    2. 否则 数组循环，当第一次满足条件的为  minCabinType
		 **/ 
		if(filterKey == 'cabinTypeMap' ){
			
			for(var c=0;c<flightItem.cabinTypeList.length;c++){
				let cabinType = flightItem.cabinTypeList[c]['cabinType'];
				// a.否则选中，则取并集   b.如果该筛选无条件选中，则默认全部满足
				if(( (tkFilterObj[filterKey][cabinType] && tkFilterObj[filterKey][cabinType]['checked']) || filterItemMapIsNull) && flightItem.cabinTypeList[c]['isShow']){
					// 只要存在一项，该航班就满足筛选条件
					isManZu1 = true;
					// 当取到第一个最低价时，继续执行循环操作，处理是否显示舱位等级问题
					if(!minCabinType){
						minCabinType = cabinType;
					}
					flightItem.cabinTypeList[c]['isNoShowCabinType'] = false;
				}else{
					flightItem.cabinTypeList[c]['isNoShowCabinType'] = true;
				}
			}
			
			// flightItem.cabinTypeList.forEach((cabinTypeItem,cabinTypeIndex)=>{
			// 	let cabinType = cabinTypeItem['cabinType'];
				
			// })
		}
		// 直飞 、经停
		if(filterKey == 'stopNumMap'){
			isManZu0 = tkCommon.filterItemIsManZu(tkFilterObj[filterKey],flightItem['stopNum'],filterItemMapIsNull )
		}
		// 航空公司 
		if(filterKey == 'airlineMap'){
			isManZu2 = tkCommon.filterItemIsManZu(tkFilterObj[filterKey],flightItem['airline'],filterItemMapIsNull )
		}
		// 起飞时间
		if(filterKey == 'depTimeMap'){
			isManZu3 = tkCommon.filterItemIsManZu(tkFilterObj[filterKey],flightItem['departTimeYjsl'],filterItemMapIsNull )
		}
		// 抵达时间
		if(filterKey == 'arrTimeMap'){
			isManZu4 =	tkCommon.filterItemIsManZu(tkFilterObj[filterKey],flightItem['arriveTimeYjsl'],filterItemMapIsNull )
		}
		//  起飞机场
		if(filterKey == 'depAirportMap'){
			isManZu5 = tkCommon.filterItemIsManZu(tkFilterObj[filterKey],flightItem['departAirport'],filterItemMapIsNull )
		}
		//  抵达机场
		if(filterKey == 'arrAirportMap'){
			isManZu6 = tkCommon.filterItemIsManZu(tkFilterObj[filterKey],flightItem['arriveAirport'],filterItemMapIsNull )
		}
		// 机型
		if(filterKey == 'planeMap'){
			isManZu7 = tkCommon.filterItemIsManZu(tkFilterObj[filterKey],flightItem['planeModelSize'],filterItemMapIsNull )
		}
	
	}
	/*
	*
	* 如果全部条件都满足
    *    1. 该航班满足筛选需求
    *    2. 该航班满足筛选条件时，  
    *     // a.处理航班数据   
    *    // b.处理筛选提交
    *     处理选项中每一个筛选项的满足 航班数， 【用于判断当前筛选条件满足下，其他筛选条件不可选择】
	*
	*
	*
	**/ 

	if(isManZu0 && isManZu1 && isManZu2 && isManZu3 && isManZu4 && isManZu5 && isManZu6 && isManZu7 ){
		//当前航班可以满足筛选条件
		isNoShow = true;
		// 处理筛选条件
		tkCommon.chuLiTkFilterObj(tkFilterObj,flightItem)
		// 处理航班数据
		if(minCabinType){
			let minCabinTypeObj = flightItem.cabinTypeList.filter((cItem,cIndex)=>{
				return cItem['cabinType'] == minCabinType
			})[0];
			let minPriceItem = minCabinTypeObj['priceArr'][0] || {};
			
			
			flightItem['minFilterPrice'] = minPriceItem['salePrice'];
			flightItem['minFilterPriceCabin'] = minPriceItem['cabin'];
			flightItem['minFilterPriceCabinType'] = minCabinType;
			flightItem['minFilterPriceCabinTypeName'] = minPriceItem['cabinTypeName'];
			flightItem['minFilterPriceId'] = minPriceItem['priceId'];
			
			flightItem['minPriceItem'] = minPriceItem;
			
		}
	}
	
	
	return {
		flightItem : flightItem,
		isNoShow: isNoShow
	}
}
// 用于判断筛选map否没有一项被选中
tkCommon.filterItemMapIsNull = function(filterItemMap){
	filterItemMap = filterItemMap || {};
	let isNull = true;
	for(var key in filterItemMap){
		if(filterItemMap[key] && filterItemMap[key]['checked']){
			isNull = false;
		}
	}
	return isNull
}

// 用于判断当前航班，当前筛选类型有没有满足
tkCommon.filterItemIsManZu = function(filterItemMap,flightItemFilterKey,filterItemMapIsNull){
	let isManzu = false;
	//   如果匹配到航司，并且航司选中，则标记为满足
	if( (filterItemMap[flightItemFilterKey]&&filterItemMap[flightItemFilterKey]['checked']) || filterItemMapIsNull){
		isManzu = true;
	}
	return isManzu
}

// 处理筛选条件中的
tkCommon.chuLiTkFilterObj = function(tkFilterObj,flightItem){
	
	// map 对应
	let filterkeyArrMap = {
		cabinTypeMap:'cabinType',  // 特别处理
		airlineMap:'airline',
		depTimeMap:'departTimeYjsl',
		arrTimeMap:'arriveTimeYjsl',
		depAirportMap:'departAirport',
		arrAirportMap:'arriveAirport',
		planeMap:'planeModelSize',
	}
	
	for(var filterKey in tkFilterObj){
		let filterItemMapIsNull = tkCommon.filterItemMapIsNull(tkFilterObj[filterKey]);
		
		// 舱位类型  
		if(filterKey == 'cabinTypeMap' ){
			flightItem.cabinTypeList && flightItem.cabinTypeList.forEach((cabinTypeItem,cabinTypeIndex)=>{
				let cabinType = cabinTypeItem['cabinType'];
					// 标记当前航班，当前舱位类型是否被选中
					// cabinTypeItem['isCabinTypeNoShow'] = false;
					// 只要存在一项，该航班就满足筛选条件
					if(tkFilterObj[filterKey][cabinType]){
						tkFilterObj[filterKey][cabinType]['filterCount'] ++;
					}
					
				
			})
		}else{
			var flightItemKey = filterkeyArrMap[filterKey];
			
			if(tkFilterObj[filterKey][flightItem[flightItemKey]]){
				tkFilterObj[filterKey][flightItem[flightItemKey]]['filterCount'] ++;
			}else{
				// console.log(flightItemKey)
				
			}
			
		}
		
	}
	
	
}


/*
 *  场景4. 排序
 *  
 *
*/ 
tkCommon.getSortNewFlightList = function(flightList,sortItemKey){
	if(!sortItemKey){
		return false
	}
	
	let sortItemKeyArr = sortItemKey.split('-') || [];
	
	function sortFlightList(arr, attrName, sort) {
	  arr.sort((a, b) => {
	    // 当按照出发时间排序时，需单独处理数据
	    var attr1 = (attrName == 'departTime' || attrName == 'arriveTime')  ? (a[attrName].replace(':', '') - 0) : a[attrName];
	    var attr2 = (attrName == 'departTime' || attrName == 'arriveTime') ? (b[attrName].replace(':', '') - 0) : b[attrName];
	    if (sort == 1) {
	      var r = attr1 > attr2; //升序
	      return r ? 1 : -1;
	
	    } else {
	      var r = attr1 < attr2; //降序
	      return r ? 1 : -1;
	    }
	  });	
	}
	// 
	sortFlightList(flightList,sortItemKeyArr[0],sortItemKeyArr[1])
}



// 是否价格超标
tkCommon.isWei=function(priceItem){
	let bool = false;
	// 如果返回违背事项，只要仅仅是[11106]【前后几小时】,就不违背
	if(priceItem['violateItem'] && priceItem['violateCode']!= '11106'){
		bool = true;
	}
	return bool
}





export default tkCommon
